import { template as template_f7d96be3c2a54c6385bc2d1901897593 } from "@ember/template-compiler";
const SidebarSectionMessage = template_f7d96be3c2a54c6385bc2d1901897593(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
