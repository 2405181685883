import { template as template_607db080404244579d8199663d414add } from "@ember/template-compiler";
const FKControlMenuContainer = template_607db080404244579d8199663d414add(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
