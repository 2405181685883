import { template as template_b0c29991aa5d411988124172d0d9f043 } from "@ember/template-compiler";
const WelcomeHeader = template_b0c29991aa5d411988124172d0d9f043(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
