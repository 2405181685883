import { template as template_61e6ed32de424df5be8176f61ba40f6f } from "@ember/template-compiler";
const FKLabel = template_61e6ed32de424df5be8176f61ba40f6f(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
